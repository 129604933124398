<template>
  <div>
    <v-row no-gutters>
      <v-col justify="right" align="right">
        <v-btn 
          text
          class="mr-2 mt-2"
          @click="applyAll"
        > Applica tutte
          <v-icon class="ml-2"> mdi-shield-account </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <GroupedSelectList
          :items="serviceTypes"
          :multi-stage="true"
          :allow-all-subcat="true"
          categories-identifier-key="id"
          items-identifier-key="id"
          categories-text-key="name"
          sub-categories-identifier-key="id"
          sub-categories-text-key="name"
          sub-categories-key="serviceTypes"
          items-key="services"
          items-text-key="name"
          v-model="enablingServicesSelected"
          @change="updateObjectForm"
        ></GroupedSelectList>
      </v-col>
      <v-col>
        <div class="text-h6 ma-1">Abilitazioni selezionate</div>
        <v-chip v-for="enablingServices in enablingServicesSelected" :key="enablingServices.id" label class="ma-1">{{enablingServices.name}}</v-chip>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import GroupedSelectList from '@/components/common/GroupedSelectList'
import serviceTypesCategoriesService from "@/services/serviceTypesCategories/serviceTypesCategories.service.js"
import enablingService from '@/services/enablings/enablings.service.js'
import operatorsForm from '@/services/operators/operator.form.js'

export default {
  name: "EnablingFormTab",
  components: {
    GroupedSelectList
  },
  data: function() {
    return {
      enablingTypeCategories: [ ],
      enablingServicesSelected: [ ],
      activeEnablingTypeCategory: undefined,
      allActive: false,
      isNew: true,
      serviceTypes: [],
    }
  },
  mounted: function() {
    this.isNew = operatorsForm.isNew
    let self = this
    operatorsForm.on('update-is-new', function(isNew) {
      self.isNew = isNew
    })

    this.fetchEnablingTypes()

  },
  methods: {
    fetchEnablingTypes() {
      serviceTypesCategoriesService.list(1, 300, this.filters)
        .then((response) => {
          this.serviceTypes = response.rows;

          if(!!this.isNew) {
            this.applyAll()
          }
        });
    },
    fetchEnablings() {
      if(!this.isNew) {
        enablingService.enablingTypeOfOperator(operatorsForm.operator).then((result) => {
          this.enablingServicesSelected = result
          operatorsForm.updateField('enablingServices', this.enablingServicesSelected)
        })
      }
    },
    updateObjectForm(cat, indexes) {
      operatorsForm.updateField('enablingServices', this.enablingServicesSelected)
    },
    applyAll() {
      if (!this.allActive) {
        this.serviceTypes.map((cat) => {
          cat.serviceTypes.map((subCat) => {
            subCat.services.map((el) => {
              if (this.enablingServicesSelected.filter(obj => obj.id == el.id).length === 0)
                this.enablingServicesSelected.push(el)
            })
          })
        })
        this.allActive = true
      } else {
        this.enablingServicesSelected = []
        this.allActive = false
      }
      this.updateObjectForm()
    }
  },
  watch: {
    isNew(newVal) {
      this.fetchEnablings()
    }
  }
}
</script>

<style>

</style>