<template>
  <div>
    <v-avatar
      :size="size"
      @click="uploadAvatar"
      :color="color"
    >
      <span
        v-if="!localAvatarUrl"
        :style="{'font-size': noImageTextFontSize, 'color': textColor}"
      >{{noImageText}}</span>
      <v-img
        v-else
        :src="localAvatarUrl"
      ></v-img>
    </v-avatar>
  </div>
</template>

<script>
import imageLoader from '@/services/images.js'

export default {
  name: "AvatarUploader",
  data: function() {
    return {
      localAvatarUrl: this.avatarUrl,
      runningLoader: undefined
    }
  },
  props: {
    value: { },
    avatarUrl: {
      type: String
    },
    size: {
      type: Number,
      default: 80
    },
    color: {
      type: String,
      default: 'primary'
    },
    textColor: {
      type: String,
      default: 'black'
    },
    noImageText: {
      type: String,
    },
    noImageTextFontSize: {
      type: String,
      default: '2rem'
    },
    imageRequireToken: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    uploadAvatar() {
      let input = document.createElement('input');
      input.type = 'file';
      input.onchange = _ => {
        let files = Array.from(input.files);
        this.localAvatarUrl = URL.createObjectURL(files[0])
        this.$emit('input', files[0])
      };
      input.click();
    },
    async loadImageUrl(url) {
      if(url) {
        this.runningLoader = imageLoader.getImageUrl(url)
        this.runningLoader.then((newUrl) => {
          this.localAvatarUrl = newUrl
        })
      } else {
        this.localAvatarUrl = undefined
      }
    }
  },
  watch: {
    avatarUrl(newVal) {
      if(this.runningLoader) {
        this.runningLoader.then(() => {
          if(this.localAvatarUrl != newVal) {
            if(this.imageRequireToken) {
              this.loadImageUrl(newVal)
            } else {
              this.localAvatarUrl = newVal
            }
          }
        })
      } else {
        if(this.localAvatarUrl != newVal) {
          if(this.imageRequireToken) {
            this.loadImageUrl(newVal)
          } else {
            this.localAvatarUrl = newVal
          }
        }
      }
    }
  }
}
</script>

<style>

</style>