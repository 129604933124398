module.exports = {
  data: function () {
    return {
      rules: {
        required: (value) => !!value || this.requiredMessage,
        email: (value) => {
          if (!this.emailValidation) {
            return true;
          } else {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || this.notAnEmailMessage;
          }
        },
        password: (value) => {
          if(value === this.localPassword ) return this.notSamePasswordMessage;
        },
      },
    };
  },
  props: {
    requiredMessage: {
      type: String,
      default: "Required.",
    },
    notAnEmailMessage: {
      type: String,
      default: "Invalid e-mail.",
    },
    notSamePasswordMessage: {
      type: String,
      default: "Password are not equal.",
    },
    emailValidation: {
      type: Boolean,
      default: false,
    },
  },
};