<template>
  <v-form v-model="formValid">
    <v-subheader class="font-italic font-weight-light py-1">Generale</v-subheader>
    <v-row class="my-3">
      <v-col align="center" justify="center">
        <AvatarUploader
          :size="100"
          v-model="avatar"
          :avatar-url="avatarUrl"
          :no-image-text="noImageText"
          :color="color"
          :text-color="textColor"
        ></AvatarUploader>
      </v-col>
    </v-row>
    <v-row no-gutters class="pa-0 py-1">
      <v-col class="pr-1">
        <v-text-field 
          label="Cognome*"
          v-model="lastname"
          filled
          hide-details="auto"
          dense
          rounded
          :rules="[presenceRule, max255CharRule]"
        ></v-text-field>
      </v-col>
      <v-col class="pr-1">
        <v-text-field 
          label="Nome*"
          v-model="firstname"
          filled
          hide-details="auto"
          dense
          rounded
          :rules="[presenceRule, max255CharRule]"
        ></v-text-field>
      </v-col>
      <v-col class="px-0">
        <v-select 
          dense
          filled
          v-model="state"
          :items="stateOptions"
          :loading="stateOptionsLoading"
          hide-details="auto"
          label="Stato*"
          :menu-props="{ offsetY: true }"
          rounded
          :rules="[presenceRule]"
        ></v-select>
      </v-col>
    </v-row>
    <v-row no-gutters class="py-1">
      <v-col class="pr-1">
        <v-select
          v-model="qualificationOperators"
          :loading="qualificationsLoading"
          :items="qualificationsOptions"
          prepend-icon="mdi-clipboard-account"
          label="Qualifica*"
          :menu-props="{ offsetY: true }"
          :rules="[presenceRule]"
          filled
          dense
          item-value="id"
          item-text="name"
          hide-details="auto"
          rounded
        ></v-select>
      </v-col>
      <v-col class="pr-1"
        @click="barcodeClick"
      >
        <BarcodeDrawer
          v-model="barcode"
          label="Barcode*"
          filled
          dense
          variant="textAndBarcodeInside"
          hide-details="auto"
          :type="!!canBarcode ? undefined : 'password'"
          :rules="[presenceRule]"
          :disabled="!canBarcode"
        ></BarcodeDrawer>
      </v-col>
      <v-col class="px-0">
        <v-select
          dense
          filled
          :loading="calendarOptionsLoading"
          v-model="calendarIds"
          :items="calendarOptions"
          prepend-icon="mdi-calendar-account"
          hide-details="auto"
          label="Calendari*"
          item-value="id"
          item-text="name"
          :menu-props="{ offsetY: true }"
          :rules="[presenceRule, categoriesRule]"
          multiple
          rounded
        ></v-select>
      </v-col>
    </v-row>

    <v-subheader class="font-italic font-weight-light py-1">Commerciale</v-subheader>
    <v-row no-gutters class="py-1">
      <v-col class="pr-1">
        <v-text-field
          style="width: 250px"
          label="Ore Contrattuali mensili*"
          v-model="contractualHours"
          filled
          type="number"
          hide-details="auto"
          dense
          rounded
          :rules="[presenceRule, greaterThanZero]"
        ></v-text-field>
      </v-col>
      <v-col class="pr-1">
        <TagsAutocomplete
          style="width: 300px"
          multiple
          label="Tags Abbinati*"
          v-model="tags"
          dense
          return-object
          :rules=[categoriesRule]
          @input="setFields('tags', $event)"
          :filters="{typeCost:true}"
        ></TagsAutocomplete>
      </v-col>
      <v-col class="px-0">
      </v-col>
      <v-col>
        <v-btn
          v-if="!!operatorId"
          class="ma-0"
          dark
          large
          :color="color"
          @click="toggleAvatarDialog"
        >
          <v-icon dark>
            mdi-camera
          </v-icon>
          Foto dell'Operatore
        </v-btn>
      </v-col>
    </v-row>

    <v-subheader class="font-italic font-weight-light py-0">Grafica</v-subheader>
    <v-row no-gutters class="pa-0">
      <v-col>
        <div class="d-flex justify-center align-center">
          <div class="mr-2">
            Colore Testo
          </div>
          <StandardColorPicker v-model="textColor"></StandardColorPicker>
        </div>
      </v-col>
      <v-col>
        <div class="d-flex justify-center align-center">
          <div class="mr-2">
            Colore Sfondo
          </div>
          <StandardColorPicker v-model="color"></StandardColorPicker>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col @click="barcodeClick">
        <UsersAutocomplete
          v-model="userId"
          :multiple="false"
          :disabled="!canBarcode"
        ></UsersAutocomplete>
      </v-col>
    </v-row>
    <StandardDialog v-model="avatarDialog" title="Avatar Operatore" dialog-width="500px" dialog-height="560px">
      <div>
        <div v-show="!cameraLoading" class="ma-0 mt-2">
          <video class="rounded-lg" v-show="!isPhotoTaken && !avatarExists" ref="camera" :width="450" :height="337.5" autoplay></video>

          <canvas class="rounded-lg" v-show="isPhotoTaken && !avatarExists" id="photoTaken" ref="canvas" :width="450"
            :height="337.5"></canvas>
          
          <v-img class="rounded-lg mb-2" v-show="avatarExists" id="avatar" :width="450" :height="337.5" :src="avatarUrl"></v-img>
        </div>

        <div v-if="!cameraLoading && !errorCamera" class="d-flex justify-center">
          <v-btn color="primary" x-large elevation4 fab @click="takePhoto">
            <a id="downloadPhoto" download="my-photo.jpg"></a>
            <v-icon x-large color="white">{{avatarExists ? 'mdi-trash-can' : 'mdi-camera'}}</v-icon>
          </v-btn>
        </div>
      </div>

      <template v-slot:footer-actions>
        <v-spacer></v-spacer>
          <v-btn
            text
            color="error"
            @click="toggleAvatarDialog"
          >
            Chiudi
          </v-btn>
        <v-btn text color="default" :loading="loadingPhotoUpload" :disabled="!isPhotoTaken" @click="uploadAvatar">Salva</v-btn>
      </template>
    </StandardDialog>

    <StandardDialog
      v-model="dialogBarcode"
      :dialog-height="null"
      dialog-max-width="500px"
      :persistent="true"
    >
      <ManualBarcodeInput
        v-model="barcodeBarcode"
        @confirm="activeBarcode"
      >
      </ManualBarcodeInput>
    </StandardDialog>
  </v-form>
</template>

<script>
import formValidable  from "@/mixins/common/formValidable.js";
import StandardColorPicker from '@/components/common/StandardColorPicker.vue'
import operatorForm from '@/services/operators/operator.form.js'
import calendarService from '@/services/calendar/calendar.service.js'
import qualificationsService from '@/services/qualifications/qualifications.service.js'
import stateMapper from '@/services/operators/statesNameMappings.js'
import AvatarUploader from '@/components/common/AvatarUploader.vue'
import UsersAutocomplete from '@/components/common/UsersAutocomplete.vue'
import BarcodeDrawer from '@/components/common/BarcodeDrawer.vue'
import TagsAutocomplete from '@/components/common/TagsAutocomplete.vue'
import imageLoader from '@/services/images.js'
import ManualBarcodeInput from '@/components/common/ManualBarcodeInput.vue';
import StandardDialog from '@/components/common/StandardDialog.vue'
import operatorService from '@/services/operators/operators.service.js'


export default {
  name: "RegistryFormTab",
  components: {
    StandardColorPicker,
    AvatarUploader,
    BarcodeDrawer,
    TagsAutocomplete,
    StandardDialog,
    UsersAutocomplete,
    ManualBarcodeInput
  },
  mixins: [formValidable],
  data: function() {
    return {
      enabled: false,
      userId: undefined,
      tags: [],
      firstname: '',
      lastname: '',
      state: undefined,
      calendarIds: undefined,
      qualificationOperators: undefined,
      textColor: '#ffffff',
      color: '#000000',
      avatar: undefined,
      avatarUrl: undefined,
      barcode: undefined,
      contractualHours: undefined,

      formValid: false,
      stateOptionsLoading: false,
      calendarOptionsLoading: false,
      qualificationsLoading: false,
      stateOptions: [],
      calendarOptions: [],
      qualificationsOptions: [],

      operatorId: undefined,
      avatarDialog: false,
      cameraLoading: false,
      isPhotoTaken: false,
      loadingPhotoUpload: false,
      avatarExists: false,
      avatarUrl: undefined,
      errorCamera: false,
      avatar: undefined,

      canBarcode: this.isNew,
      dialogBarcode: false,
      barcodeBarcode: undefined,
    };
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    isNew: {
      type: Boolean,
      default: false
    },
  },
  mounted: async function() {
    this.calendarOptionsLoading = true
    
    await calendarService.calendarsList().then((result) => {
      this.calendarOptions = result
      this.calendarOptionsLoading = false
      this.qualificationsLoading = true
      qualificationsService.cachedList().then((results) => {
        this.qualificationsOptions = results
        this.qualificationsLoading = false
        this.stateOptionsLoading = true
        stateMapper.options().then((options) => {
          this.stateOptions = options
          this.state = options[0].value
          this.stateOptionsLoading = false
          this.handleObjectChanges(operatorForm.operator)
          var self = this
          operatorForm.on('update', function(data) {
            self.handleObjectChanges(data.operator)
            self.operatorId = data.operator.id
          })
        })
      })
    })

    if (!!this.color)
      await operatorForm.updateField('color', this.color)
    if (!!this.textColor)
      await operatorForm.updateField('textColor', this.textColor)
  },
  methods: {
    fields() {
      return ['firstname', 'lastname', 'state', 'contractualHours', 'calendarIds', 'qualificationOperators', 'textColor', 'color', 'barcode', 'tags', 'userId']
    },
    handleObjectChanges(operator) {
      const fields = this.fields()
      const newValKeys = Object.keys(operator)

      for(let i = 0; i < fields.length; i++) {
        const field = fields[i]
        if(newValKeys.includes(field) && operator[field] != this[field]) {
         this[field] = operator[field]
        }
      }

      if(newValKeys.includes('avatar')) {
        this.avatarUrl = operator['avatar']
      } else {
        this.avatarUrl = undefined
      }
    },
    setFields(name, value) {
      operatorForm.updateField(name, value)
    },
    max255CharRule: (v) => {
      if (v) {
        return v.length <= 255 || "Al massimo 255 caratteri";
      } else {
        return true;
      }
    },
    presenceRule: (v) => {
      return !!v || "Il campo è obbligatorio";
    },
    greaterThanZero: (v) => {
      return v > 0 || "Il numero deve essere positivo";
    },
    categoriesRule: (v) => {
      if (!!v && v.length > 0)
        return true
      else
        return 'Il campo è obbligatorio'
    },
    async toggleAvatarDialog() {
      if (this.avatarDialog) {
        this.isPhotoTaken = false
        this.avatarDialog = false
        this.avatarExists = false
      } else if(!!this.avatar){
        this.avatarDialog = true;
        this.avatarExists = true
        this.avatarUrl = await imageLoader.getImageUrl(this.avatar)
      } else {    
        this.avatarDialog = true;
        this.createCameraElement()
      }
    },
    createCameraElement() {
      this.isLoading = true;

      const constraints = (window.constraints = {
        audio: false,
        video: true
      });

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => {
          this.isLoading = false;
          this.$refs.camera.srcObject = stream;
          this.errorCamera = false
        })
        .catch(error => {
          this.isLoading = false;
          alert("May the browser didn't support or there is some errors.");
          this.errorCamera = true
        });
    },
    takePhoto() {
      if(this.avatarExists){
        this.createCameraElement()
        this.avatarExists = false
      } else {
        this.isPhotoTaken = !this.isPhotoTaken;

        const context = this.$refs.canvas.getContext('2d');
        context.drawImage(this.$refs.camera, 0, 0, 450, 337.5);
      }
    },
    async uploadAvatar() {
      this.loadingPhotoUpload = true
      const canvas = document.getElementById("photoTaken")
      canvas.download = "AvatarTmp.png"

      var data = new FormData()
      let imageBlob = await new Promise(resolve => canvas.toBlob(resolve, 'image/png'));
      data.append("avatar", imageBlob, 'avatar.png')

      await operatorService.uploadAvatarPhoto(this.operatorId, data)
      this.loadingPhotoUpload = false
      this.avatar = await operatorService.reloadAvatar(this.operatorId)
      this.avatarUrl = await imageLoader.getImageUrl(this.avatar)
      this.toggleAvatarDialog()
    },
    barcodeClick() {
      if(!this.canBarcode){
        this.dialogBarcode = true
      }
    },
    activeBarcode() {
      operatorService.canPerformOperation(this.barcodeBarcode, "Barcode").then((result) => {
        if (result) {
          this.canBarcode = true
        } else {
          alert("Non hai i permessi per eseguire questa azione!")
        }
      }).catch((err) => {
        console.error(err)
        alert("Badge non riconosciuto")
      })
      this.barcodeBarcode = undefined
      this.dialogBarcode = false
    },
  },
  computed: {
    noImageText() {
      let result = ''
      if(this.firstname) {
        result += this.firstname[0]
      }
      if(this.lastname) {
        result += this.lastname[0]
      }
      return result
    },
  },
  watch: {
    formValid(newVal) {
      this.$emit('update:valid', newVal)
      if(this.bus) {
        this.bus.$emit('update:valid', newVal)
      }
      operatorForm.setValid(newVal)
    },
    enabled(newVal) {
      operatorForm.updateField('enabled', newVal)
    },
    firstname(newVal) {
      operatorForm.updateField('firstname', newVal)
    },
    lastname(newVal) {
      operatorForm.updateField('lastname', newVal)
    },
    state(newVal) {
      operatorForm.updateField('state', newVal)
    },
    contractualHours(newVal) {
      operatorForm.updateField('contractualHours', newVal)
    },
    calendarIds(newVal) {
      operatorForm.updateField('calendarIds', newVal)
    },
    qualificationOperators(newVal) {
      operatorForm.updateField('qualificationOperators', newVal)
    },
    textColor(newVal) {
      operatorForm.updateField('textColor', newVal)
    },
    color(newVal) {
      operatorForm.updateField('color', newVal)
    },
    avatar(newVal) {
      //operatorForm.updateField('avatar', newVal)
    },
    barcode(newVal) {
      operatorForm.updateField('barcode', newVal)
    },
    userId(newVal) {
      operatorForm.updateField('userId', newVal)
    }
  }
};
</script>

<style>
</style>