import eventsHandler from '@/services/common/eventsHandler.js'

class OperatorForm extends eventsHandler {
  constructor() {
    super()
    this._operator = {}
    this._valid = false
  }

  get operator() {
    const operator = {
      ...this._operator
    }
    return operator
  }

  get valid() {
    return this._valid
  }

  get isNew() {
    return !this._operator.id
  }

  setValid(valid) {
    this._valid = valid
    this._executeCallbacksOf('valid-change', valid)
  }

  resetOperator() {
    this._operator = {}
    this._executeCallbacksOf('reset')
    this._executeCallbacksOf('update', {operator: {}})
  }

  updateField(key, value) {
    this._operator[key] = value
    this._executeCallbacksOf('update', {operator: this._operator})
    this._executeCallbacksOf('update-' + key, {key: this._operator[key]})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }

  updateObject(operator) {
    this._operator = operator
    this._executeCallbacksOf('update', {operator: this._operator})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }
}

export default new OperatorForm()